import React from "react";

export const ExpandIcon = (props: any) => {
  return (
    <div {...props}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.52855 4.86201C7.7889 4.60166 8.21101 4.60166 8.47136 4.86201L13.138 9.52868C13.3984 9.78903 13.3984 10.2111 13.138 10.4715C12.8777 10.7318 12.4556 10.7318 12.1952 10.4715L7.99996 6.27622L3.8047 10.4715C3.54435 10.7318 3.12224 10.7318 2.86189 10.4715C2.60154 10.2111 2.60154 9.78903 2.86189 9.52868L7.52855 4.86201Z"
          fill="#A1A1AA"
        />
      </svg>
    </div>
  );
};
