import { action, makeAutoObservable, observable } from "mobx";
import React from "react";
import DappStore from "./modules/DappStore";
import { Client, KeyPairsType, SearchUsersResponse } from "@web3mq/client";
import { AxiosInstance } from "axios";
import { Request } from "../utils/request";
import { message } from "antd";

export type MainKeysType = {
  publicKey: string;
  privateKey: string;
  walletAddress: string;
};

export enum showModalEnum {
  CREATE_DAPP_MODAL,
  DAPP_MANAGE_MODAL,
}

export default class AppStore {
  @observable client?: Client;
  @observable dappStore?: DappStore;
  @observable keys?: KeyPairsType;
  @observable mainKeys?: MainKeysType;
  @observable showLoading = false;
  @observable showModalType: showModalEnum | undefined;
  @observable request?: AxiosInstance = undefined;
  @observable myProfile?: any = undefined;

  @action loginSuccess = async (client: Client) => {
    this.showLoading = true;
    this.client = client;
    // this.dappStore = new DappStore(client);
    // await this.dappStore.getDappList();
    if (!this.myProfile) {
      const res = await client.user.getMyProfile();
      this.setMyProfile(res);
    }
    this.showLoading = false;
  };
  @action.bound setMyProfile = async (profile: any) => {
    this.myProfile = profile;
  };

  @action setShowModalType = (data: showModalEnum | undefined) => {
    this.showModalType = data;
  };
  @action setKeys = (keys: KeyPairsType) => {
    this.keys = keys;
  };
  @action setMainKeys = (mainKeys: MainKeysType) => {
    this.mainKeys = mainKeys;
  };

  @action logout = () => {
    localStorage.removeItem("PRIVATE_KEY");
    localStorage.removeItem("PUBLIC_KEY");
    localStorage.removeItem("userid");
    this.client = undefined;
    this.keys = undefined;
    this.dappStore = undefined;
    this.showLoading = false;
  };

  init(cb: () => void) {
    const mainPrivateKey = localStorage.getItem(`MAIN_PRIVATE_KEY`);
    const mainPublicKey = localStorage.getItem(`MAIN_PUBLIC_KEY`);
    const address = localStorage.getItem("WALLET_ADDRESS");
    if (mainPublicKey && mainPrivateKey && address) {
      this.setMainKeys({
        publicKey: mainPublicKey,
        privateKey: mainPrivateKey,
        walletAddress: address,
      });
    }
    const PrivateKey = localStorage.getItem("PRIVATE_KEY") || "";
    const PublicKey = localStorage.getItem("PUBLIC_KEY") || "";
    const userid = localStorage.getItem("userid") || "";

    if (PrivateKey && PublicKey && userid) {
      this.setKeys({ PrivateKey, PublicKey, userid });
      new Promise(async function (resolve) {
        const tempPubkey = localStorage.getItem("PUBLIC_KEY") || "";
        const didKey = localStorage.getItem("DID_KEY") || "";
        const fastUrl: string = await Client.init({
          connectUrl: localStorage.getItem("FAST_URL"),
          app_key: "vAUJTFXbBZRkEDRE",
          env: "test",
          didKey,
          tempPubkey,
        });
        localStorage.setItem("FAST_URL", fastUrl);
        new Request(fastUrl, tempPubkey, didKey);
        const client = Client.getInstance({ PrivateKey, PublicKey, userid });
        resolve(client);
      })
        .then(async (client: any) => {
          await this.loginSuccess(client);
          cb && cb();
        })
        .catch((err: any) => {
          console.error(err.message);
        });
    } else {
      cb && cb();
    }

    makeAutoObservable(this); //even though this isn't required in some examples, this seems key line to making mobx work
  }
}
export const StoreContext = React.createContext(new AppStore());
export const StoreProvider = StoreContext.Provider;
export const useStore = () => React.useContext(StoreContext);
