import React, { ReactElement } from "react";
import { Navigate, RouteProps } from "react-router-dom";
import { useStore } from "./mobx/service";

const PrivateRoute = ({ children }: RouteProps): ReactElement => {
  const store = useStore();
  if ((!store.myProfile || !store.myProfile.userid) && !store.showLoading) {
    return (
      <Navigate
        to={{
          pathname: "/login",
        }}
      />
    );
  }

  return children as ReactElement;
};

export default PrivateRoute;
