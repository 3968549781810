import React from "react";

export const ModifyIcon = (props: any) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4226 3.57774C16.0972 3.2523 15.5695 3.2523 15.2441 3.57774L8.33333 10.4885L8.33333 11.667H9.51184L16.4226 4.75625C16.748 4.43081 16.748 3.90317 16.4226 3.57774ZM14.0656 2.39923C15.0419 1.42291 16.6248 1.42291 17.6011 2.39923C18.5774 3.37554 18.5774 4.95845 17.6011 5.93476L10.4463 13.0896C10.29 13.2459 10.078 13.3337 9.85702 13.3337H7.5C7.03976 13.3337 6.66667 12.9606 6.66667 12.5003L6.66667 10.1433C6.66667 9.92229 6.75446 9.71033 6.91074 9.55405L14.0656 2.39923ZM5 5.00033C4.53976 5.00033 4.16667 5.37342 4.16667 5.83366V15.0003C4.16667 15.4606 4.53976 15.8337 5 15.8337H14.1667C14.6269 15.8337 15 15.4606 15 15.0003V10.8337C15 10.3734 15.3731 10.0003 15.8333 10.0003C16.2936 10.0003 16.6667 10.3734 16.6667 10.8337V15.0003C16.6667 16.381 15.5474 17.5003 14.1667 17.5003H5C3.61929 17.5003 2.5 16.381 2.5 15.0003V5.83366C2.5 4.45295 3.61929 3.33366 5 3.33366H9.16667C9.6269 3.33366 10 3.70676 10 4.16699C10 4.62723 9.6269 5.00033 9.16667 5.00033H5Z"
        fill="#71717A"
      />
    </svg>
  );
};
