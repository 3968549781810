import React, { ReactNode, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import ss from "./index.module.scss";
import { showModalEnum, useStore } from "../../mobx/service";
import { observer } from "mobx-react-lite";
import {
  MetaMaskIcon,
  ModifyIcon,
  // DappManageIcon,
  IsCurrentIcon,
} from "../../icons";
import { getShortAddress } from "../../utils/util";
import { DappItemType } from "../../types";
import { LogoutIcon } from "../../icons/LogoutIcon";

export const DappPopover: React.FC = observer(() => {
  const navigate = useNavigate();
  const store = useStore();
  const handleLogout = () => {
    store.logout();
    navigate("/login");
  };

  const dappMangeConfig = useMemo(() => {
    return [
      // {
      //   icon: <CreateDappIcon style={{ height: "15px" }} />,
      //   title: "Create a new Dapp",
      //   handler: async () => {
      //     store.setShowModalType(showModalEnum.CREATE_DAPP_MODAL);
      //   },
      // },
      {
        icon: <ModifyIcon height={20} width={20} />,
        title: "Change account name",
        handler: async () => {
          navigate("/fill?nickname=" + store.myProfile?.nickname);
        },
      },
    ];
  }, []);

  const RenderDappItem = useCallback(
    (props: { dapp: DappItemType }) => {
      const { dapp } = props;
      const { dapp_id, dapp_description, dapp_name } = dapp;
      return (
        <div
          className={ss.dappListItem}
          onClick={() => {
            store.dappStore?.setCurrentDapp(dapp);
          }}
        >
          <div className={ss.dappAvatar}>
            {dapp_name.substr(0, 2).toUpperCase()}
          </div>
          <div className={ss.dappInfoBox}>
            <div className={ss.dappName}>{dapp_id}</div>
            <div className={ss.dappDesc}>{dapp_description || dapp_id}</div>
          </div>
          {store.dappStore?.currentDapp?.dapp_id === dapp_id && (
            <div className={ss.isCurrentDapp}>
              <IsCurrentIcon />
            </div>
          )}
        </div>
      );
    },
    [store.dappStore?.dappList]
  );

  const RenderDappManageBox = useCallback(
    (props: { icon: ReactNode; title: string; handler: any }) => {
      const { icon, title, handler } = props;
      return (
        <div className={ss.dappManageItem} onClick={handler}>
          {icon}
          <div className={ss.dappManageTitle}>{title}</div>
        </div>
      );
    },
    []
  );

  return (
    <div className={ss.dappPopoverBox}>
      <div className={ss.connectedWalletBox}>
        <div className={ss.mr6}>
          <MetaMaskIcon width={20} height={20} />
        </div>
        <div className={ss.mr6}>MetaMask</div>
        <div>{getShortAddress(store.mainKeys?.walletAddress) || "0x..."}</div>
      </div>
      {/* <div className={ss.dappListBox}>
        {store.dappStore?.dappList.map((item) => {
          return <RenderDappItem dapp={item} key={item.dapp_id} />;
        })}
      </div> */}
      <div className={ss.dappManageBox}>
        {dappMangeConfig.map((item, index) => {
          return (
            <RenderDappManageBox
              key={index}
              icon={item.icon}
              title={item.title}
              handler={item.handler}
            />
          );
        })}
      </div>
      <div className={ss.logoutBox}>
        <RenderDappManageBox
          icon={<LogoutIcon />}
          title="Logout"
          handler={handleLogout}
        />
      </div>
    </div>
  );
});
