import React, { lazy } from "react";
import { Route, Navigate } from "react-router-dom";
import { UserGroupAnalysisIcon } from "../icons";
import NotFound from "../pages/errorPage/404";
import { UserGroupManagementIcon } from "../icons/UserGroupManagementIcon";
import { PushIcon } from "../icons/PushIcon";
import Layout from "../components/Layout";
import ProtectedRoute from "../protectedRoute";
import Management from "../pages/userGroup/Management";
import PushCreate from "../pages/push/create";
import PushList from "../pages/push/list";

import Login from "../pages/login";
import Fill from "../pages/fill";

export type routeType = {
  path: string;
  component?: React.ComponentType;
  icon?: React.ReactNode;
  meta?: any;
  name?: string;
  redirect?: string;
  children?: routeType[];
  activePath?: string[];
  isPublic?: boolean;
};

export const routes: routeType[] = [
  {
    path: "/login",
    isPublic: true,
    component: () => <Login />,
  },
  {
    path: "/fill",
    component: () => <Fill />,
  },
  {
    path: "/",
    component: () => <Layout />,
    children: [
      {
        path: "/",
        redirect: "/push/list",
      },
      // {
      //   path: "/overview",
      //   name: "OverView",
      //   children: [
      //     {
      //       path: "/overview",
      //       redirect: "/overview/usergroupmanagement",
      //     },
      //     // {
      //     //   path: "/overview/usergroupanalysis",
      //     //   name: "User Group Analysis",
      //     //   icon: <UserGroupAnalysisIcon />,
      //     //   component: lazy(() => import("../pages/userGroup/Analysis")),
      //     // },
      //     {
      //       path: "/overview/usergroupmanagement",
      //       name: "User Group Management",
      //       icon: <UserGroupManagementIcon />,
      //       component: () => <Management />,
      //     },
      //   ],
      // },
      {
        path: "/push",
        name: "Message Push",
        children: [
          {
            path: "/push/create",
            component: () => <PushCreate />,
          },
          {
            path: "/push/list",
            name: "Push Service",
            icon: <PushIcon />,
            component: () => <PushList />,
            activePath: ["/push/create"],
          },
        ],
      },
    ],
  },
  {
    path: "/createdapp",
    component: lazy(
      () => import(/* webpackChunkName: "login" */ "../pages/createDapp")
    ),
  },
  {
    path: "*",
    component: lazy(() => import("../pages/errorPage/404")),
    meta: {
      title: 404,
    },
  },
];
export const renderRouter = (routerList: routeType[]) => {
  return routerList.map((route) => {
    const { path, children, isPublic, redirect } = route;

    if (redirect) {
      return (
        <Route
          path={path}
          key={`${path}${redirect}`}
          element={<Navigate to={redirect} replace={true} />}
        ></Route>
      );
    }
    if (!route.component && children) {
      return (
        <Route key={path} path={path}>
          {!!children && renderRouter(children)}
        </Route>
      );
    }
    if (!route.component && !children) {
      return <NotFound />;
    }
    if (route.component) {
      return (
        <Route
          key={path}
          path={path}
          element={
            isPublic ? (
              <route.component />
            ) : (
              <ProtectedRoute>
                <route.component />
              </ProtectedRoute>
            )
          }
        >
          {!!children && renderRouter(children)}
        </Route>
      );
    }
    return null;
  });
};
