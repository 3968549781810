export function getShortAddress(
  address: string = "",
  num: number = 5,
  endNum = 4
) {
  let strLength = address.length;
  return (
    address.substring(0, num) +
    "..." +
    address.substring(strLength - endNum, strLength)
  );
}