import React from "react";

export const LogoutIcon = (props: any) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.83333 4.16667C4.91286 4.16667 4.16667 4.91286 4.16667 5.83333V14.1667C4.16667 15.0871 4.91286 15.8333 5.83333 15.8333H9.16667C10.0871 15.8333 10.8333 15.0871 10.8333 14.1667V13.3333C10.8333 12.8731 11.2064 12.5 11.6667 12.5C12.1269 12.5 12.5 12.8731 12.5 13.3333V14.1667C12.5 16.0076 11.0076 17.5 9.16667 17.5H5.83333C3.99238 17.5 2.5 16.0076 2.5 14.1667V5.83333C2.5 3.99238 3.99238 2.5 5.83333 2.5H9.16667C11.0076 2.5 12.5 3.99238 12.5 5.83333V6.66667C12.5 7.1269 12.1269 7.5 11.6667 7.5C11.2064 7.5 10.8333 7.1269 10.8333 6.66667V5.83333C10.8333 4.91286 10.0871 4.16667 9.16667 4.16667H5.83333ZM14.4107 6.07741C14.7362 5.75197 15.2638 5.75197 15.5893 6.07741L18.9226 9.41074C19.248 9.73618 19.248 10.2638 18.9226 10.5893L15.5893 13.9226C15.2638 14.248 14.7362 14.248 14.4107 13.9226C14.0853 13.5972 14.0853 13.0695 14.4107 12.7441L16.3215 10.8333L6.66667 10.8333C6.20643 10.8333 5.83333 10.4602 5.83333 10C5.83333 9.53976 6.20643 9.16667 6.66667 9.16667L16.3215 9.16667L14.4107 7.25592C14.0853 6.93049 14.0853 6.40285 14.4107 6.07741Z"
        fill="#71717A"
      />
    </svg>
  );
};
