import React from "react";

export const PlusIcon = (props: any) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 0C6.36819 0 6.66667 0.298477 6.66667 0.666667V5.33333H11.3333C11.7015 5.33333 12 5.63181 12 6C12 6.36819 11.7015 6.66667 11.3333 6.66667H6.66667V11.3333C6.66667 11.7015 6.36819 12 6 12C5.63181 12 5.33333 11.7015 5.33333 11.3333V6.66667H0.666667C0.298477 6.66667 0 6.36819 0 6C0 5.63181 0.298477 5.33333 0.666667 5.33333H5.33333V0.666667C5.33333 0.298477 5.63181 0 6 0Z"
        fill="white"
      />
    </svg>
  );
};
