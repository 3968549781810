import React from "react";

export const PushIcon = (props: any) => {
  return (
    <div {...props}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.00016 5.33333C4.00016 4.96514 4.29864 4.66667 4.66683 4.66667H11.3335C11.7017 4.66667 12.0002 4.96514 12.0002 5.33333C12.0002 5.70152 11.7017 6 11.3335 6H4.66683C4.29864 6 4.00016 5.70152 4.00016 5.33333Z"
          fill="#18181B"
        />
        <path
          d="M4.00016 8C4.00016 7.63181 4.29864 7.33333 4.66683 7.33333H7.3335C7.70169 7.33333 8.00016 7.63181 8.00016 8C8.00016 8.36819 7.70169 8.66667 7.3335 8.66667H4.66683C4.29864 8.66667 4.00016 8.36819 4.00016 8Z"
          fill="#18181B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.3335 4C1.3335 2.89543 2.22893 2 3.3335 2H12.6668C13.7714 2 14.6668 2.89543 14.6668 4V9.33333C14.6668 10.4379 13.7714 11.3333 12.6668 11.3333H10.943L8.47157 13.8047C8.21122 14.0651 7.78911 14.0651 7.52876 13.8047L5.05735 11.3333H3.3335C2.22893 11.3333 1.3335 10.4379 1.3335 9.33333V4ZM3.3335 3.33333C2.96531 3.33333 2.66683 3.63181 2.66683 4V9.33333C2.66683 9.70152 2.96531 10 3.3335 10H5.3335C5.51031 10 5.67988 10.0702 5.8049 10.1953L8.00016 12.3905L10.1954 10.1953C10.3204 10.0702 10.49 10 10.6668 10H12.6668C13.035 10 13.3335 9.70152 13.3335 9.33333V4C13.3335 3.63181 13.035 3.33333 12.6668 3.33333H3.3335Z"
          fill="#18181B"
        />
      </svg>
    </div>
  );
};
