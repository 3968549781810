import React from "react";

export const PrivewBackIcon = (props: any) => {
  return (
    <div {...props}>
      <svg
        width="12"
        height="22"
        viewBox="0 0 12 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.60206 11L11.4062 3.05085C11.9866 2.4597 11.9778 1.50999 11.3867 0.929623C10.7955 0.349253 9.84583 0.357994 9.26546 0.949147L0.429623 9.94915C-0.143208 10.5326 -0.143208 11.4674 0.429623 12.0509L9.26546 21.0509C9.84583 21.642 10.7955 21.6507 11.3867 21.0704C11.9778 20.49 11.9866 19.5403 11.4062 18.9491L3.60206 11Z"
          fill="#007AFF"
        />
      </svg>
    </div>
  );
};
