const headers = {
  "Content-Type": "application/json",
};

export const post = async (url: string, data: any) => {
  const response = await fetch(url, {
    method: "POST",
    headers,
    body: JSON.stringify(data),
  });

  return await response.json();
};
export const get = async (url: string, data?: any) => {
  const response = await fetch(url, {
    method: "GET",
    headers,
    body: JSON.stringify(data),
  });

  return await response.json();
};
