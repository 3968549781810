import React, { useEffect, useState } from "react";
import { AppTypeEnum, Button, LoginModal } from "@web3mq/react-components";
import { ConnectWalletIcon, LoginBgcIcon, LoginCenterIcon } from "../../icons";
import { useStore } from "../../mobx/service";
import { Client } from "@web3mq/client";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Request, request } from "../../utils/request";
import ss from "./index.module.scss";

const Login: React.FC = () => {
  const store = useStore();
  const navigate = useNavigate();
  const [fastestUrl, setFastUrl] = useState<string | null>(null);

  const init = async () => {
    const tempPubkey = localStorage.getItem("PUBLIC_KEY") || "";
    const didKey = localStorage.getItem("DID_KEY") || "";
    const fastUrl = await Client.init({
      connectUrl: localStorage.getItem("FAST_URL"),
      app_key: "vAUJTFXbBZRkEDRE",
      env: "test",
      didKey,
      tempPubkey,
    });
    new Request(fastUrl, tempPubkey, didKey);
    setFastUrl(fastUrl);
  };

  const handleLoginEvent = async (eventData: any) => {
    if (eventData.data) {
      if (eventData.type === "login") {
        const {
          privateKey,
          publicKey,
          tempPrivateKey,
          tempPublicKey,
          didKey,
          userid,
          address,
          pubkeyExpiredTimestamp,
        } = eventData.data;
        localStorage.setItem("userid", userid);
        localStorage.setItem("PRIVATE_KEY", tempPrivateKey);
        localStorage.setItem("PUBLIC_KEY", tempPublicKey);
        localStorage.setItem("WALLET_ADDRESS", address);
        localStorage.setItem(`MAIN_PRIVATE_KEY`, privateKey);
        localStorage.setItem(`MAIN_PUBLIC_KEY`, publicKey);
        localStorage.setItem(`DID_KEY`, didKey);
        localStorage.setItem(
          "PUBKEY_EXPIRED_TIMESTAMP",
          String(pubkeyExpiredTimestamp)
        );
        store.setKeys({
          PrivateKey: tempPrivateKey,
          PublicKey: tempPublicKey,
          userid,
        });

        new Promise(function (resolve) {
          const client = Client.getInstance({
            PrivateKey: tempPrivateKey,
            PublicKey: tempPublicKey,
            userid,
          });
          // @ts-ignore
          request.defaults.headers["web3mq-request-pubkey"] = tempPublicKey;
          // @ts-ignore
          request.defaults.headers["didkey"] = didKey;
          resolve(client);
        }).then(async function (client: any) {
          const data = await client.user.getMyProfile();
          await store.setMyProfile(data);
          await store.loginSuccess(client);
          if (data.nickname) {
            navigate("/");
          } else {
            navigate("/fill");
          }
        });
      }
      if (eventData.type === "register") {
        const { privateKey, publicKey, address } = eventData.data;
        localStorage.setItem("WALLET_ADDRESS", address);
        localStorage.setItem(`MAIN_PRIVATE_KEY`, privateKey);
        localStorage.setItem(`MAIN_PUBLIC_KEY`, publicKey);
      }
    }
  };

  useEffect(() => {
    init();
    document.body.setAttribute("data-theme", "light");
  }, []);

  // if (store.keys) {
  //   new Promise(function (resolve) {
  //     if (store.keys) {
  //       const client = Client.getInstance(store.keys);
  //       // @ts-ignore
  //       request.defaults.headers["web3mq-request-pubkey"] =
  //         store.keys.PublicKey;
  //       // @ts-ignore
  //       request.defaults.headers["didkey"] = localStorage.getItem(`DID_KEY`);
  //       resolve(client);
  //     }
  //   }).then(async function (client: any) {
  //     await store.loginSuccess(client);
  //     navigate("/");
  //   });
  // }

  if (!store.mainKeys) {
    const mainPrivateKey = localStorage.getItem(`MAIN_PRIVATE_KEY`);
    const mainPublicKey = localStorage.getItem(`MAIN_PUBLIC_KEY`);
    const address = localStorage.getItem("WALLET_ADDRESS");
    if (mainPublicKey && mainPrivateKey && address) {
      store.setMainKeys({
        publicKey: mainPublicKey,
        privateKey: mainPrivateKey,
        walletAddress: address,
      });
    }
  }

  return (
    <div className={ss.login_container}>
      <div className={ss.testBgc}>
        <LoginBgcIcon />
      </div>
      <div className={ss.connectBtnBox}>
        <LoginCenterIcon />
        <div className={ss.connectBtnBoxTitle}>Welcome to Web3MQ</div>
        <div className={ss.connectBtnBoxText}>
          Let's get started with your decentralized trip now!
        </div>
        <div className={ss.walletConnectBtnBox}>
          <LoginModal
            env={"test"}
            containerId={""}
            handleLoginEvent={handleLoginEvent}
            keys={store.mainKeys}
            appType={AppTypeEnum.pc}
            styles={{
              addressBox: {
                width: "281px",
              },
            }}
            loginBtnNode={
              <Button
                icon={<ConnectWalletIcon />}
                type={"primary"}
                className={ss.walletConnectBtn}
                disabled={!fastestUrl}
              >
                {fastestUrl ? "Connect" : "Initializing"}
              </Button>
            }
          />
        </div>
      </div>
    </div>
  );
};
export default observer(Login);
