import React from "react";

export const BackIcon = (props: any) => {
  return (
    <div {...props}>
      <svg
        width="9"
        height="14"
        viewBox="0 0 9 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.08917 0.578062C8.41461 0.903499 8.41461 1.43114 8.08917 1.75657L2.8451 7.00065L8.08917 12.2447C8.41461 12.5702 8.41461 13.0978 8.08917 13.4232C7.76374 13.7487 7.2361 13.7487 6.91066 13.4232L1.07733 7.58991C0.751893 7.26447 0.751893 6.73683 1.07733 6.4114L6.91066 0.578062C7.2361 0.252625 7.76374 0.252625 8.08917 0.578062Z"
          fill="#18181B"
        />
      </svg>
    </div>
  );
};
