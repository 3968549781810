import React, { useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import cx from "classnames";

import ss from "./index.module.scss";
import { routeType } from "src/routers";
import { ExpandIcon, CollapseIcon, DefualtUserLogoIcon } from "../../icons";
import { DappPopover } from "../DappPopover";
import { Popover } from "antd";

type MenuProps = {
  menuList: routeType[];
  avatar_url: string;
  nickname: string;
};

export const Menu: React.FC<MenuProps> = (props) => {
  const { menuList, avatar_url, nickname } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const [poped, setPoped] = useState(false);
  const menuStyle = useCallback((index: number) => {
    const paddingLeft = index * 12;
    return {
      paddingLeft,
    };
  }, []);

  const RenderMenuItem = useCallback(
    (menuList: routeType[], tableIndex = 1) => {
      return menuList.map((menu) => {
        if (menu.redirect) return null;
        if (!menu.name) return null;
        if (!menu.children)
          return (
            <li
              key={menu.name}
              className={cx(ss.menuItem, {
                [ss.isActive]:
                  location.pathname === menu.path ||
                  (menu.activePath &&
                    menu.activePath.indexOf(location.pathname) >= 0),
              })}
              style={menuStyle(tableIndex - 1)}
              onClick={() => navigate(menu.path)}
            >
              {menu.icon && <span className={ss.icon}>{menu.icon}</span>}
              <div className={ss.title}>{menu.name}</div>
            </li>
          );
        return (
          <li
            key={menu.name}
            className={cx(ss.subMenu, {
              [ss.isActive]: location.pathname === menu.path && menu.component,
            })}
          >
            <div className={ss.subMenuTitle} style={menuStyle(tableIndex)}>
              {menu.name}
            </div>
            {menu.children && (
              <ul className={ss.sub}>
                {RenderMenuItem(menu.children, tableIndex + 1)}
              </ul>
            )}
          </li>
        );
      });
    },
    [location.pathname]
  );
  const handleOpenChange = (newOpen: boolean) => {
    setPoped(newOpen);
  };
  return (
    <>
      <div
        style={{
          width: 250,
          minWidth: 250,
          maxWidth: 250,
          overflow: "hidden",
        }}
      />
      <aside className={ss.menuAsideContainer} style={{ width: 250 }}>
        <div className={ss.headerBox}>
          <Popover
            arrow={false}
            content={<DappPopover />}
            placement="bottomLeft"
            // trigger="click"
            open={poped}
            onOpenChange={handleOpenChange}
          >
            <div className={ss.dappBox}>
              <div style={{ display: "flex" }}>
                {avatar_url ? (
                  <img
                    width={24}
                    height={24}
                    style={{ borderRadius: "50%" }}
                    src={avatar_url}
                    alt=""
                  />
                ) : (
                  <DefualtUserLogoIcon />
                )}
                <div className={ss.dappName}>{nickname}</div>
              </div>
              {poped ? <ExpandIcon /> : <CollapseIcon />}
            </div>
          </Popover>
        </div>
        <div style={{ height: "100%" }}>
          <ul>{RenderMenuItem(menuList)}</ul>
        </div>
      </aside>
    </>
  );
};
