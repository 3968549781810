import React, { useEffect, useState } from "react";
import { BackIcon } from "../../../icons/BackIcon";
import { Tabs } from "antd";
import type { TabsProps } from "antd";
import dayjs from "dayjs";

import { Typography } from "antd";
import {
  Button,
  DatePicker,
  Col,
  Row,
  TimePicker,
  Form,
  Input,
  Select,
} from "antd";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";

import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";
import type { UploadChangeParam } from "antd/es/upload";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import { EditorState, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { convertToHTML as draftToHtml } from "draft-convert";

import { useStore } from "../../../mobx/service";
import { post, get } from "../../../utils/fetch";
import { PrivewBackIcon, Web3MQIcon } from "../../../icons";
import s from "./index.module.scss";

const { Title } = Typography;

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result as string));
  reader.readAsDataURL(img);
};
const beforeUpload = (file: RcFile) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
};

const Create: React.FC = (props) => {
  const navigate = useNavigate();
  const store = useStore();

  const [loading, setLoading] = useState(false);
  const [logoUrl, setLogoUrl] = useState<string>();
  const [fileList, setFileList] = useState<UploadFile<any>[]>([]);
  const [submitLoading, setSubmitLoading] = useState(false);

  const [time, setTime] = useState(Date.now());

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [form] = Form.useForm();

  const onChange = (key: string) => {
    console.log(key);
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Alert push",
    },
    {
      key: "2",
      label: "Feed push",
    },
  ];

  const uploadLogoButton = (
    <div className={s.uploadBtn}>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
    </div>
  );

  const handleLogoChange: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj as RcFile, (url) => {
        setLoading(false);
        setLogoUrl(url);
      });
    }
  };
  const normFile: UploadProps["onChange"] = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  const handleImgChange: UploadProps["onChange"] = ({
    fileList: newFileList,
  }) => setFileList(newFileList);
  const onFormChange = () => {
    setTime(Date.now());
  };
  const onSubmit = async () => {
    await form.validateFields();
    try {
      let data: any = form.getFieldsValue();
      data.push_timestamp = dayjs().valueOf(); // data.push_timestamp.valueOf();
      data.timestamp = dayjs().valueOf(); // data.timestamp.valueOf();
      data.group_list = [data.group_list];
      data.push_type = "feed";
      data.web3mq_userid = store.myProfile.userid;
      setSubmitLoading(true);

      const res = await post(
        "https://testnet-dapp-server.web3mq.com/api/push/job_list/",
        data
      );
      if (res.code === 0) {
        navigate("/push/list");
      } else {
        message.error(res.msg);
      }
    } catch (error: any) {
      message.error(error.message);
    } finally {
      setSubmitLoading(false);
    }
  };
  const goBack = () => {
    navigate("/push/list");
  };
  const getHmtl = () => {
    let text = editorState.getCurrentContent();
    let html = draftToHtml({
      entityToHTML: (entity, originalText) => {
        if (entity.type === "LINK") {
          return (
            <a target="_blank" rel="noreferrer" href={entity.data.url}>
              {originalText}
            </a>
          );
        }
        return originalText;
      },
    })(text);
    return html;
  };
  useEffect(() => {
    let html = getHmtl();
    html = html.replace(/<p>/g, "").replace(/<\/p>/g, `\n`);
    if (html !== `\n`) {
      form.setFieldValue("content", html);
      form.validateFields();
    }
  }, [editorState, form]);
  return (
    <div className={s.pushContainer}>
      <header>
        <BackIcon className={s.backIcon} onClick={goBack} />
        <div className={s.text}>New Push</div>
      </header>
      <main style={{ display: "flex" }}>
        {/* <Tabs defaultActiveKey="1" items={items} onChange={onChange} /> */}

        <Form
          form={form}
          wrapperCol={{ span: 22 }}
          layout="vertical"
          style={{
            width: 600,
            minWidth: 484,
            borderRight: "1px solid #F4F4F5",
          }}
          onChange={onFormChange}
        >
          <Title style={{ margin: "24px 0 24px 0" }} level={5}>
            Push Basic Information
          </Title>
          {/* <Form.Item
            label="Avatar"
            name="logo"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            // rules={[{ required: true, message: "Please upload your avatar!" }]}
            className={s.formItem}
            extra={
              <div className={s.formText}>
                Pick a logo for your Avatar. Recommended size is 256×256px.
                Avatars are only available for Feed style
              </div>
            }
          >
            <Upload
              accept="image/png, image/jpeg, image/jpg"
              className={s.upload}
              showUploadList={false}
              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              // beforeUpload={beforeUpload}
              onChange={handleLogoChange}
            >
              {logoUrl ? (
                <img src={logoUrl} alt="avatar" style={{ width: "100%" }} />
              ) : (
                uploadLogoButton
              )}
            </Upload>
          </Form.Item> */}
          <Form.Item
            label="Title"
            name="title"
            className={s.formItem}
            rules={[{ required: true, message: "Please input your title!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Content"
            name="content"
            extra={
              <div className={s.formText}>
                Only plain text and hyperlinks are supported
              </div>
            }
            rules={[{ required: true, message: "Please input your content!" }]}
          >
            {/* <Input /> */}
            <div className={s.editor}>
              <Editor
                editorState={editorState}
                onEditorStateChange={setEditorState}
                toolbar={{
                  options: ["inline", "link", "history"],
                  inline: {
                    options: ["bold", "italic", "underline"],
                  },
                  link: {
                    defaultTargetOption: "_blank",
                    showOpenOptionOnHover: false,
                    // inDropdown: true,
                    // showTargetOption: true,
                  },
                }}
              />
            </div>
          </Form.Item>

          {/* <Form.Item
            label="Image"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            className={s.formItem}
            name="dataset['pictures']"
            extra={
              <div className={s.formText}>
                Up to 4 images can be uploaded, the recommended size is 1:1 or
                16:9, and the image quality is less than 1MB
              </div>
            }
          >
            <Upload
              accept="image/png, image/jpeg, image/jpg"
              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              listType="picture-card"
              maxCount={4}
              // fileList={fileList}

              // onPreview={handlePreview}
              onChange={handleImgChange}
            >
              {fileList.length >= 4 ? null : (
                <div>
                  <PlusOutlined />
                </div>
              )}
            </Upload>
          </Form.Item> */}

          <Form.Item
            className={s.formItem}
            label="Select push user group"
            name="group_list"
            initialValue={"all"}
          >
            <Select>
              <Select.Option value="all">All</Select.Option>
            </Select>
          </Form.Item>

          {/* <Form.Item label="Select push time">
            <Row>
              <Col span={11}>
                <Form.Item
                  name="push_timestamp"
                  rules={[{ required: true, message: "Please select date !" }]}
                >
                  <DatePicker style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col span={2}></Col>
              <Col span={11}>
                <Form.Item
                  name="timestamp"
                  rules={[{ required: true, message: "Please select  time!" }]}
                >
                  <TimePicker style={{ width: "100%" }} />
                </Form.Item>
              </Col>
            </Row>
          </Form.Item> */}

          <Form.Item className={s.formItem} style={{ paddingTop: 0 }}>
            <Button
              htmlType="submit"
              onClick={onSubmit}
              loading={submitLoading}
              className={classNames(s.submit, s.active)}
            >
              Confirm
            </Button>
          </Form.Item>
        </Form>

        <div className={s.right}>
          {/* <div className={s.tabs}></div> */}
          <div className={s.privew}>
            <div className={s.bar}>
              <div className={s.back}>
                <PrivewBackIcon />
                <span>Back</span>
              </div>
              <div className={s.mid}>
                <div>Web3MQ Bot</div>
                <div>1 subscriber</div>
              </div>
              <Web3MQIcon />
            </div>
            <div className={s.content}>
              <div className={s.card}>
                <div className={s.nickname}>
                  {(store.myProfile && store.myProfile.nickname) ||
                    "Account Name"}
                </div>
                <div className={s.title}>
                  {form.getFieldValue("title") || "title"}
                </div>
                <div
                  className={s.detail}
                  dangerouslySetInnerHTML={{
                    __html: getHmtl() || "content",
                  }}
                ></div>
                <div className={s.linkPrivew}></div>
              </div>
            </div>
            <div className={s.input}></div>
          </div>
          <div className={s.footer}>
            The push style is displayed in real time with the information you
            fill in the UI
          </div>
        </div>
      </main>
    </div>
  );
};
export default Create;
