import React, { Suspense } from "react";
// import { createRoot } from "react-dom/client";
import ReactDOM from "react-dom";

import { Routes, BrowserRouter } from "react-router-dom";
import { renderRouter, routes } from "./routers";
import AppStore, { StoreProvider } from "./mobx/service";
import "./index.css";
import "@web3mq/react-components/dist/css/index.css";
import { ConfigProvider } from "antd";
import { StyleProvider } from "@ant-design/cssinjs";

document.body.setAttribute("data-theme", "light");
const container = document.getElementById("root");

const appStore = new AppStore();
appStore.init(() => {
  ReactDOM.render(
    <React.StrictMode>
      <StoreProvider value={appStore}>
        <BrowserRouter>
          <Suspense fallback={<></>}>
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: "#663CEE",
                },
              }}
            >
              <StyleProvider hashPriority="high">
                <Routes>{renderRouter(routes)}</Routes>
              </StyleProvider>
            </ConfigProvider>
          </Suspense>
        </BrowserRouter>
      </StoreProvider>
    </React.StrictMode>,
    container
  );
});
//initiating persisted instance of state store
// const root = createRoot(container!);
