import React, { useEffect, useState } from "react";
import { LoginBgcIcon } from "../../icons";
import { useNavigate, useLocation } from "react-router-dom";
import qs from "qs";

import ss from "./index.module.scss";
import { Button, Form, Input, message } from "antd";
import classNames from "classnames";
import { useStore } from "../../mobx/service";

const Fill: React.FC = () => {
  const store = useStore();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const parsedQuery = qs.parse(location.search.replace("?", ""));
  const [isVaild, setIsVaild] = useState(!!parsedQuery.nickname);

  const onFinish = async (values: any) => {
    try {
      setLoading(true);
      const res: any = await store.client?.user.updateMyProfile(
        values.nickname,
        store.myProfile.avatar_url
      );
      if (res.code === 0) {
        await store.setMyProfile(res.data);
        navigate("/");
      } else {
        message.error(res.msg);
      }
    } catch (error: any) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const onChange = (e: any) => {
    form
      .validateFields()
      .then(() => {
        setIsVaild(true);
      })
      .catch(() => {
        setIsVaild(false);
      });
  };

  return (
    <div className={ss.login_container}>
      <div className={ss.testBgc}>
        <LoginBgcIcon />
      </div>
      <div>
        <div className={ss.connectBtnBoxTitle}>Welcome to Web3MQ Push</div>
        <div className={ss.connectBtnBoxText}>
          Please complete your user information first{" "}
        </div>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          className={ss.form}
          onChange={onChange}
        >
          <Form.Item
            name="nickname"
            label="Account Name"
            initialValue={parsedQuery.nickname}
            rules={[
              {
                required: true,
                max: 30,
                message: "Please enter your Account Name (max 30 characters)!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item noStyle>
            <Button
              type="primary"
              loading={loading}
              disabled={!isVaild}
              className={classNames(ss.btn, !isVaild ? "" : ss.active)}
              htmlType="submit"
            >
              Upload
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
export default Fill;
