import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import classNames from "classnames";
import {
  Table,
  Divider,
  Tag,
  Button,
  message,
  ConfigProvider,
  Radio,
  Modal,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import type { RadioChangeEvent } from "antd";
import { SmileOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../../mobx/service";
import { get, post } from "../../../utils/fetch";
import s from "./index.module.scss";
import { PlusIcon } from "../../../icons";

interface DataType {
  key: string;
  name: string;
  age: number;
  address: string;
  tags: string[];
}

const customizeRenderEmpty = () => (
  <div className={s.empty}>
    <SmileOutlined style={{ fontSize: 20 }} />
    <p>Data Not Found</p>
  </div>
);
const List: React.FC = (props) => {
  const navigate = useNavigate();
  const store = useStore();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modal, contextHolder] = Modal.useModal();
  // const [delLoading, setDelLoading] = useState(false);

  const resend = async (row: any) => {
    try {
      const res = await post(
        `https://testnet-dapp-server.web3mq.com/api/push/job_retry/`,
        {
          jobid: row.jobid,
          timestamp: Date.now(),
          web3mq_userid: store.myProfile.userid,
        }
      );
      if (res.code === 0) {
        message.success("re-send succeed!");
      } else {
        message.error(res.msg);
      }
    } catch (error: any) {
      message.error(error.message);
    }
  };

  const onResendClick = (row: any) => {
    modal.confirm({
      title: "Whether to re-push this message？",
      icon: "",
      content: (
        <div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <label>Push title</label>
            <span>{row.title}</span>
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", marginTop: 20 }}
          >
            <label>Push content</label>
            <span>{row.content}</span>
          </div>
        </div>
      ),
      okText: "Push",
      cancelText: "Cancel",
      onOk: () => {
        resend(row);
      },
    });
  };
  const handleDelete = (row: any) => {
    modal.confirm({
      title: "Whether to delete push messages？",
      icon: "",
      content:
        "Deleting this push message will not affect the messages already sent",
      okText: "Confirm",
      cancelText: "Cancel",
      onOk: () => {
        // onDeleteClick(row);
      },
    });
  };
  const columns: ColumnsType<DataType> = [
    // {
    //   title: "User Group",
    //   dataIndex: "group_list",
    //   key: "group_list",
    //   width: 100,
    //   render: (_, row: any) => {
    //     if (row.group_list && row.group_list.length > 0) {
    //       return row.group_list.map((group: string) => {
    //         return <span key={group}>{group}</span>;
    //       });
    //     }
    //     return "-";
    //   },
    // },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Content",
      dataIndex: "content",
      key: "content",
    },
    // {
    //   title: "Image",
    //   dataIndex: "dataset['pictures']",
    //   key: "dataset['pictures']",
    //   render: (_, row: any) => {
    //     if (
    //       row.dataset &&
    //       row.dataset.pictures &&
    //       row.dataset.pictures.length > 0
    //     ) {
    //       return row.dataset.pictures.map((pic: string) => {
    //         return <img key={pic} src={pic} alt="" height={24} width={24} />;
    //       });
    //     }
    //     return "-";
    //   },
    // },
    {
      title: "Push Time",
      dataIndex: "pushtime",
      key: "pushtime",
      width: 180,
      render: (_, row: any) => (
        <>
          <span>{dayjs(row.push_timestamp).format("YYYY-MM-DD")}</span>
          <span style={{ marginLeft: 10 }}>
            {dayjs(row.timestamp).format("HH:mm:ss")}
          </span>
        </>
      ),
    },
    {
      title: "Push Status",
      key: "status",
      dataIndex: "status",
      width: 95,
      render: (_, row) => {
        if (_ === 1) return <Tag color="success">success</Tag>;
        if (_ === -1) return <Tag color="error">Fail</Tag>;
        return <Tag color="default">Timing</Tag>;
      },
    },
    // {
    //   title: "Operation",
    //   key: "operation",
    //   width: 150,
    //   render: (_, record) => (
    //     <>
    //       <Button
    //         type="link"
    //         onClick={() => {
    //           onResendClick(record);
    //         }}
    //       >
    //         Resend
    //       </Button>
    //       {/* <Divider type="vertical" />
    //       <Button
    //         type="link"
    //         danger
    //         onClick={() => {
    //           handleDelete(record);
    //         }}
    //       >
    //         Delete
    //       </Button> */}
    //     </>
    //   ),
    // },
  ];

  const onChange = (e: RadioChangeEvent) => {
    console.log(`radio checked:${e.target.value}`);
  };
  const onClick = () => {
    navigate("/push/create");
  };
  const fetchList = async () => {
    setLoading(true);
    try {
      const res = await get(
        `https://testnet-dapp-server.web3mq.com/api/push/job_list/?timestamp=${Date.now()}&web3mq_userid=${
          store.myProfile.userid
        }`
      );
      if (res.code === 0) {
        setList(res.data.data_list);
      } else {
        message.error(res.msg);
      }
    } catch (error: any) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (store.myProfile && store.myProfile.userid) {
      fetchList();
    }
  }, []);
  return (
    <div className={s.pushContainer}>
      <header>
        <div className={s.text}>Push Service</div>
        <Button onClick={onClick} className={classNames(s.btn)}>
          <PlusIcon width={12} height={12} />
          <span className={classNames(s.btnTxt)}>New Push</span>
        </Button>
      </header>
      <main>
        <div className={s.history}>
          <div className={s.subText}>Push History</div>
          {/* <Radio.Group onChange={onChange} defaultValue="a">
            <Radio.Button value="a">Alert</Radio.Button>
            <Radio.Button value="d">Feed</Radio.Button>
          </Radio.Group> */}
        </div>
        <ConfigProvider renderEmpty={customizeRenderEmpty}>
          <Table loading={loading} columns={columns} dataSource={list} />
        </ConfigProvider>
      </main>
      {contextHolder}
    </div>
  );
};
export default React.memo(List);
