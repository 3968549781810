import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import ss from "./index.module.scss";
import { routes } from "../../routers";
import { Menu } from "../Memu";
import { showModalEnum, useStore } from "../../mobx/service";
import { observer } from "mobx-react-lite";
import { Modal } from "@web3mq/react-components";
import CreateDappForm from "../CreateDappForm";
import { CloseIcon } from "../../icons";

const Layout: React.FC = () => {
  const store = useStore();
  const navigate = useNavigate();
  // const DappCreateHead = useCallback(() => {
  //   let title = "";
  //   if (store.showModalType === showModalEnum.CREATE_DAPP_MODAL) {
  //     title = "Create a new Dapp";
  //   }
  //   if (store.showModalType === showModalEnum.DAPP_MANAGE_MODAL) {
  //     title = "Dapp Management";
  //   }
  //   return (
  //     <div className={ss.createDappHeader}>
  //       <div></div>
  //       <div className={ss.headTitle}>{title}</div>
  //       <div
  //         className={ss.closeIcon}
  //         onClick={() => {
  //           store.setShowModalType(undefined);
  //         }}
  //       >
  //         <CloseIcon />
  //       </div>
  //     </div>
  //   );
  // }, []);
  const menuList = useMemo(() => {
    return routes.filter((route) => route.path === "/")[0].children || [];
  }, []);

  useEffect(() => {
    document.body.setAttribute("data-theme", "light");
  }, []);

  // useEffect(() => {
  //   if (!store.client && !store.showLoading) {
  //     navigate("/login");
  //   }
  //   if (store.myProfile && store.myProfile.nickname === "") {
  //     navigate("/fill");
  //   }
  // }, [navigate, store.client, store.showLoading, store.myProfile]);

  // if (!store.dappStore?.currentDapp && !store.showLoading) {
  //   navigate("/fill");
  //   return null;
  // }
  // useEffect(() => {
  //   if (!store.client && !store.showLoading) {
  //     navigate("/login");
  //   }
  // }, [store.client, navigate, store.showLoading]);
  if (store.showLoading) {
    return (
      <div className={ss.layout}>
        <div>Loading...</div>
      </div>
    );
  }

  return (
    <div className={ss.layout}>
      <div className={ss.main}>
        <Menu
          menuList={menuList}
          avatar_url={store.myProfile?.avatar_url}
          nickname={store.myProfile?.nickname}
        />
        <Outlet />
      </div>

      {/* {store.client && (
        <Modal
          dialogClassName={ss.web3mqModal}
          modalHeader={<DappCreateHead />}
          visible={store.showModalType !== undefined}
          closeModal={() => {
            store.setShowModalType(undefined);
          }}
        >
          {store.showModalType === showModalEnum.CREATE_DAPP_MODAL && (
            <CreateDappForm
              submitEvent={async (submitRes: any) => {
                if (submitRes && submitRes.dapp_id) {
                  await store.dappStore?.getDappList();
                  store.setShowModalType(undefined);
                  store.dappStore?.setCurrentDapp(submitRes);
                  navigate("/");
                }
              }}
            />
          )}

          {store.showModalType === showModalEnum.DAPP_MANAGE_MODAL && (
            <CreateDappForm
              isEdit={true}
              submitEvent={async (submitRes: any) => {
                if (submitRes && submitRes.dapp_id) {
                  await store.dappStore?.getDappList();
                  store.setShowModalType(undefined);
                  store.dappStore?.setCurrentDapp(submitRes);
                  navigate("/");
                }
              }}
            />
          )}
        </Modal>
      )} */}
    </div>
  );
};
export default observer(Layout);
