import React from "react";

export const CollapseIcon = (props: any) => {
  return (
    <div {...props}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.86189 5.52864C3.12224 5.26829 3.54435 5.26829 3.8047 5.52864L7.99996 9.7239L12.1952 5.52864C12.4556 5.26829 12.8777 5.26829 13.138 5.52864C13.3984 5.78899 13.3984 6.2111 13.138 6.47145L8.47136 11.1381C8.21101 11.3985 7.7889 11.3985 7.52855 11.1381L2.86189 6.47145C2.60154 6.2111 2.60154 5.78899 2.86189 5.52864Z"
          fill="#A1A1AA"
        />
      </svg>
    </div>
  );
};
